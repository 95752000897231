import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import './ClosePage.scss';

class ClosePage extends Component {
  render() {
    let to = '/';
    let label = '';
    for(let item of this.props.items) {
      if (this.props.active === item.id) {
        to = item.path;
        label = item.header;
      }
    }

    if(to === this.props.location.pathname || label === '') {
      return "";
    } else {
      return (
        <Link to={to} className="ClosePage">{label}</Link>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    active: state.accordion.activeAccordionItem,
    items: state.accordion.items
  }
};

export default withRouter(connect(mapStateToProps)(ClosePage));
