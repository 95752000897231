import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import './Logo.scss';
import LogoOpen from '../../../img/logo-open.png';
import LogoClosed from '../../../img/logo-closed.png';

class Logo extends Component {
  render() {
    return (
      <div className="SiteLogo">
        <Link to="/">
          <img src={LogoOpen} alt="Symfona" className="Large" onClick={() => {this.props.closeDrawer();}} />
          <img src={LogoClosed} alt="Symfona" className="Small" />
        </Link>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeDrawer: () => dispatch({
      type: 'SIDE_DRAWER_CLOSE',
      showSideDrawer: false,
      from: 'Logo'
    })
  }
};

export default connect(null, mapDispatchToProps)(Logo);
