/* import TagManager from 'react-gtm-module';
 * const tagManagerArgs = {
 *   gtmId: 'GTM-57ZH4LH'
 * };
 * TagManager.initialize(tagManagerArgs);
*/
import ReactGA from 'react-ga';

const trackLocation = location => {
  ReactGA.initialize('UA-47306769-2');
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

export {
  trackLocation
}
