import React, {Component} from 'react';
import {connect} from 'react-redux';
import Paragraphs from '../Paragraphs/Paragraphs';
import './Welcome.scss';

class Welcome extends Component {
    render() {
        return (
            <div className="Welcome">
              <div className="wrapper">
                <h2 className="Welcome-title">{ this.props.homeTitle }</h2>
                <Paragraphs paragraphs={this.props.homeContent} />
              </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    homeTitle: state.homeTitle,
    homeContent: state.homeContent
  }
};

export default connect(mapStateToProps)(Welcome);
