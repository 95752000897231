import React, {Component} from 'react';
import Logo from './Logo/Logo';
import DrawerToggle from './SideDrawer/DrawerToggle/DrawerToggle';
import SideDrawer from "./SideDrawer/SideDrawer";
import ClosePage from './ClosePage/ClosePage';
import Aux from '../../hoc/Aux/Aux';
import Backdrop from './Backdrop/Backdrop';
import {connect} from 'react-redux';

import './Toolbar.scss';


class Toolbar extends Component {

  render() {
    let toolbarClasses = (this.props.showSideDrawer)
      ? ['Toolbar', 'Open']
      : ['Toolbar', 'Closed'];
    return (
      <Aux>
        <Backdrop show={this.props.showSideDrawer} clicked={() => this.props.onSideDrawerClose()}/>
        <header className={toolbarClasses.join(' ')}>
          <Logo/>
          <DrawerToggle clicked={() => this.props.onSideDrawerToggle(this.props.showSideDrawer)}/>
          <SideDrawer open={this.props.showSideDrawer} />
          <ClosePage/>
        </header>
      </Aux>
    )
  }
}

const mapDispatchToProps = dispatch => {

  return {
    onSideDrawerClose: () => {
      return dispatch({type: 'SIDE_DRAWER_CLOSE', showSideDrawer: false})
    },
    onSideDrawerToggle: (current) => {
      return dispatch({type: 'SIDE_DRAWER_TOGGLE', showSideDrawer: !current})
    },
  }
};

const mapStateToProps = (state) => {
  return {
    showSideDrawer: state.showSideDrawer,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
