import React from 'react';
import Copyright from './Copyright/Copyright';
import LangSwitch from './LangSwitch/LangSwitch';
import NavigationItems from './NavigationItems/NavigationItems';
import './SideDrawer.scss';

const sideDrawer = (props) => {
  let attachedClasses = ['SideDrawer'];
  if (props.open) {
    attachedClasses.push('Open');
  }
  else {
    attachedClasses.push('Close')
  }
  return (
    <div className={attachedClasses.join(' ')}>
      <NavigationItems/>
      <LangSwitch/>
      <Copyright/>
    </div>
  );
};

export default sideDrawer;
