import React, { Component } from 'react';
import { connect } from 'react-redux';

import './NavigationItems.scss';
import NavigationItem from './NavigationItem/NavigationItem';

class NavigationItems extends Component {
  render() {
    const navItems = this.props.links.map((link) => (
      <NavigationItem
        key={link.to}
        link={link.to}
        className={link.className}
        exact><span onClick={() => {this.props.closeDrawer();}}>{link.text}</span></NavigationItem>
    ));

    return (
      <nav>
        <ul className="NavigationItems">
          {navItems}
        </ul>
      </nav>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeDrawer: () => dispatch({
      type: 'SIDE_DRAWER_CLOSE',
      showSideDrawer: false,
      from: 'NavigationItems'
    })
  }
};

const mapStateToProps = (state) => {
  return {
    links: state.navItems,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItems);
