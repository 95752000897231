import React from 'react';


const Home = (props) => {

    return (
        <div className="display-hidden">hidden element</div>
    );
};

export default Home;
