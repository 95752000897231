import React, {Component} from 'react';
import JumpNav from '../UI/JumpNav/JumpNav';
import ParagraphText from './ParagraphText/ParagraphText';
import ParagraphImg from './ParagraphImg/ParagraphImg';
import Aux from '../../hoc/Aux/Aux';


class Paragraphs extends Component {

  renderParagraph(data, key) {
    switch (data.type) {
      case 'ParagraphText':
        return <ParagraphText {...data} key={key}/>;
      case 'ParagraphImg':
        return <ParagraphImg {...data} key={key}/>;

      default:
    }
    return '';
  }

  render() {
    let paragraphs = [];
    let navItems = [];

    if (typeof this.props.paragraphs !== 'undefined') {
      let iterator = 1;
      for (let paragraph of this.props.paragraphs) {
        paragraphs.push(this.renderParagraph(paragraph, iterator++));
        if (typeof paragraph.title !== 'undefined' && paragraph.title) {
          navItems.push({ title: paragraph.title, hash: paragraph.hash})
        }
      }
    }

    let jumpNav = [];
    if (typeof this.props.jumpnav !== 'undefined' && this.props.jumpnav && window.innerWidth >= 768) {
      jumpNav.push(<JumpNav key={'jumpnav'} navItems={navItems}/>)
    }

    return (
      <Aux>
        {jumpNav}
        <div className="paragraphs">
            {paragraphs}
        </div>
      </Aux>
    );

  }
}

export default Paragraphs;
