import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import logo from '../../img/logo-open.png';
import CookieNote from './CookieNote/CookieNote';

import './Auth.scss';
import Menu from '../../components/Menu/Menu';
import queryString from 'query-string';

class Auth extends Component {

  render() {
    const { cookies } = this.props;

    const cookiesCheck = (
      this.props.location.pathname !== '/logout' &&
      typeof cookies.get('docCheck') !== 'undefined'
    );
    let timeCheck = false;

    if (!cookiesCheck) {

      const queryParams = queryString.parse(this.props.location.search.slice(1));
      const pathTimestamp = (queryParams.dc_timestamp !== 'undefined')
        ? parseInt(queryParams.dc_timestamp, 10) * 1000
        : 0;
      const nowTimestamp = parseInt(Date.now(), 10);

      timeCheck = (typeof queryParams.dc_timestamp !== 'undefined')
        ? (pathTimestamp - nowTimestamp <= 3660000)
        : false;
    }


    if (cookiesCheck || (timeCheck && this.props.location.pathname === "/login/doccheck")) {
      if (!cookiesCheck) {
        cookies.set('docCheck', Date.now(), {
          path: '/',
          maxAge: 60*60*24
        });
      }
      this.props.onLoginSuccess();
    }

    let loginTitle = '';
    let loginText = [];
    let legalMenu = {};
    let cookieNote;

    let userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.match(/^fr/g);
    userLang = (userLang !== null) ? 'fr' : 'de';

    for(let lang of this.props.languages) {
      if (lang.id === userLang) {
        loginTitle = lang.loginTitle;
        loginText = lang.loginText;
        legalMenu = lang.legalMenu;
        cookieNote = lang.cookieNote;
      }
    }

    const text = loginText.map(el => (<p
      key={el.slice(1,10)}
      dangerouslySetInnerHTML={{__html: el}}
    />));

    return (
      <div className="Auth">
        <div className="Salutation">
          <img src={logo} alt="SYMFONA" width="250" height="51" />
          <h1 className="Title">{ loginTitle }</h1>
          {text}

          <Menu {...legalMenu}/>
        </div>

        <div className="LoginWrapper">
          <iframe align="left" frameBorder="0" scrolling="no"
                  width="311" height="188" name="dc_login_iframe"
                  id="dc_login_iframe" title="DC login"
                  src="https://login.doccheck.com/code/de/2000000012909/login_m/">
            <a
              href="https://login.doccheck.com/code/de/2000000012909/login_m/"
              target="_blank" rel="noopener noreferrer">LOGIN</a></iframe>
          <CookieNote {...cookieNote} />
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginSuccess: () => dispatch({ type: 'LOGIN_SUCCESS', loggedIn: true, cookiesAccepted: true }),
    acceptCookies: () => dispatch({ type: 'ACCEPT_COOKIES', cookiesAccepted: true})
  }
};

const mapStateToProps = (state) => {
  return {
    languages: state.languages,
    currentLang: state.languages
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
