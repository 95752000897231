import React from 'react';
import './Copyright.scss';

const Copyright = () => (
  <div className="Copyright">
    <p>Copyright &copy;&nbsp;2018&nbsp;Vifor&nbsp;SA.<br/>
      All rights reserved
    </p>
  </div>
);

export default Copyright;
