import React, {Component} from 'react';

import Aux from '../Aux/Aux';
import './Layout.scss';
import Toolbar from '../../components/Toolbar/Toolbar';
import Welcome from '../../components/Welcome/Welcome';
import Accordion from '../../components/Accordion/Accordion';
import axios from '../../axios-content';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

class Layout extends Component {

  loadContent() {
    if(
      this.props.loggedIn &&
      this.props.location.pathname !== '/logout' &&
      this.props.currentLang !== ''
    ) {
      let query = '/files/content_' + this.props.currentLang + '.json';
      axios.get(query)
        .then(result => {
          if (typeof result.data.pages !== 'undefined') {
            this.props.onPagesReceived(result.data.pages, this.props.currentLang);
            this.props.onAccordionItemsReceived(result.data.accordionItems);
            this.props.onNavItemsReceived(result.data.navItems);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  componentDidUpdate() {
    if (this.loadedLang !== this.props.currentLang) {
      this.loadContent();
      this.loadedLang = this.props.currentLang;
    }
  }

  componentDidMount() {
    this.loadContent();
    this.loadedLang = this.props.currentLang;
  }

  componentWillMount() {
    const pathFrags = this.props.location.pathname.split('/').filter(word => word.length >= 1);
    let lang = 'de';
    if (pathFrags.length && pathFrags[0].length === 2) {
      for (let language of this.props.languages) {
        if (language.id === pathFrags[0]) {
          lang = language.id;
        }
      }
    }
    this.props.onLanguageReceived(lang);
  }

  render() {
    return (
      <Aux>
        <Toolbar/>
        <div className="Content">
          <Welcome/>
          <Accordion/>

          {this.props.children}
        </div>
      </Aux>
    )
  }
}

const mapDispatchToProps = dispatch => {

  return {
    onPagesReceived: (pages, lang) => {
      let homePath = (lang !== 'de') ? '/'+ lang : '/';
      let home = {pageTitle: '', paragraphs: []};
      if(typeof pages[homePath] !== 'undefined') {
        home = pages[homePath];
        delete pages[homePath];
      }
      return dispatch({type: 'CONTENT_SET_PAGES', pages: pages, home: home})
    },
    onAccordionItemsReceived: (accordionItems) => {
      return dispatch({type: 'ACCORDION_SET_ITEMS', accordionItems: accordionItems})
    },
    onNavItemsReceived: (navItems) => {
      return dispatch({type: 'NAVIGATION_SET_ITEMS', navItems: navItems})
    },
    onLanguageReceived: (lang) => {
      return dispatch({type: 'SET_LANGUAGE', currentLang: lang})
    }
  }
};

const mapStateToProps = (state) => {
  return {
    currentLang: state.currentLang,
    languages: state.languages,
    loggedIn: state.loggedIn
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
