import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import Page from './containers/Page/Page';
import Auth from './containers/Auth/Auth';
import { withCookies } from 'react-cookie';
import { trackLocation } from './ga_gtm';

import './App.scss';

class App extends Component {
  componentDidUpdate(prevProps) {
    if(this.props.location.pathname !== prevProps.location.pathname) {
      trackLocation(this.props.location);
    }

    if (this.props.location.pathname === '/logout' && this.props.loggedIn) {
      this.doLogout()
    } else {
      this.setAccordionActiveItem();
    }

    if (this.props.currentLang !== prevProps.currentLang) {
      document.documentElement.lang = this.props.currentLang
    }
  }

  componentDidMount() {
    this.setAccordionActiveItem();
  }

  doLogout() {
    const { cookies } = this.props;
    if (cookies.get('docCheck')) {
      cookies.remove('docCheck', { path: '/' });
    }
    if(this.props.loggedIn) {
      this.props.onLogout();
    }
  }

  setAccordionActiveItem() {

    const pathname = this.props.location.pathname;
    const pageOpen = (typeof this.props.pages[pathname] !== 'undefined');
    if (pageOpen !== this.props.pageOpen) {
      this.props.setPageOpen(pageOpen);
    }

    const pathFrags = pathname.split("/").filter((el) => el !== "");
    let activeBasePath;
    if(this.props.currentLang === 'de') {
      activeBasePath = (pathFrags.length) ? pathFrags[0] : false;
    } else {
      activeBasePath = (pathFrags.length >= 2) ? [pathFrags[0], pathFrags[1]].join('/') : false;
    }

    let accordionActiveItem = 0;
    for(let route of this.props.routes) {

      if(typeof route.path === 'string' && route.path === '/' + activeBasePath) {
        accordionActiveItem = route.id;
      }
    }
    if(accordionActiveItem !== this.props.accordionActiveItem) {
      this.props.onRouteEntered(accordionActiveItem);
    }
  }

  render() {
    let classes = ['App'];
    if (typeof this.props.pages[this.props.location.pathname] !== 'undefined') {
      classes.push('PageOpen')
    }

    const routes = this.props.routes.map(route => {
      return (
        <Route path={route.path} key={route.id} component={Page}/>
      );
    });

    let pageContent = (this.props.loggedIn)
      ? (
        <Layout>
          <Switch>
            {routes}
            <Route path="/" exact component={Home}/>
          </Switch>
        </Layout>)
      : (
        <Auth cookies={this.props.cookies}/>
      );
    return (
      <div className={classes.join(' ')} data-state={this.props.pageOpen}>
        {pageContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const pages = state.pages;
  const routes = state.accordion.items.map(item => {
    return {
      path: item.path,
      id: item.id
    }
  });
  return {
    currentLang: state.currentLang,
    pages: pages,
    routes: routes,
    loggedIn: state.loggedIn,
    pageOpen: state.pageOpen,
    accordionActiveItem: state.accordion.activeAccordionItem,
  }
};


const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => {
      return dispatch({
        type: 'LOGOUT',
        loggedIn: false,
        homeTitle: '',
        homeContent: [],
        pageOpen: false,
        showSideDrawer: false,
        currentLang: '',
        accordion: {
          activeAccordionItem: 0,
          items: []
        },
        navItems: [],
        pages: {}
      })
    },
    setPageOpen: (pageOpen) => dispatch({
      type: 'SET_PAGE_OPEN',
      pageOpen: pageOpen,
      from: 'APP'
    }),
    onRouteEntered: (id) => {
      return dispatch({
        type: 'ACCORDION_SET_ACTIVE_ITEM',
        id: id,
        from: 'APP'
      })
    }
  }
};

export default compose(withRouter, withCookies, connect(mapStateToProps, mapDispatchToProps))(App);
