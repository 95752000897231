import React, { Component } from 'react';
import './ParagraphText.scss';
import ScrollableAnchor from 'react-scrollable-anchor';

//

class ParagraphText extends Component {

  render() {
    let classes = ['Paragraph', 'Text'];

    let title = (this.props.title) ? <h2 className="ParagraphTitle">{this.props.title}</h2>
      : '';

    let textParagraph = (<empty/>);
    if (this.props.hash) {
      textParagraph = (
        <ScrollableAnchor id={this.props.hash} >
          <div id={this.props.hash + "-spy"} className={classes.join(' ')}>
            {title}
            <div className="ParagraphBody" dangerouslySetInnerHTML={{__html: this.props.body}} />
          </div>
        </ScrollableAnchor>
      );
    } else {
      textParagraph = (
        <div className={classes.join(' ')}>
          {title}
          <div className="ParagraphBody" dangerouslySetInnerHTML={{__html: this.props.body}} />
        </div>
      )
    }

    return textParagraph;
  };
}

export default ParagraphText;
