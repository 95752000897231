import React, { Component } from 'react';
import { connect } from 'react-redux';
import './LangSwitch.scss';



class LangSwitch extends Component {

  render() {
    const langItems = this.props.languages.map((language) => (
      <li
        key={language.id}
        onClick={() => this.props.onLangClicked(language.id)}
        >{language.name}</li>
    ));

    return (
      <ul className="LangSwitch">
        { langItems }
      </ul>
    )
  }
}

const mapDispatchToProps = dispatch => {

  return {
    onLangClicked: (currentLang) => {
      return dispatch({type: 'SET_LANGUAGE', currentLang: currentLang})
    },
  }
};


const mapStateToProps = (state) => {
  return {
    languages: state.languages,
    currentLang: state.currentLang
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LangSwitch);
