import React, {Component} from 'react';
import { connect } from 'react-redux'
import './CookieNote.scss';

class CookieNote extends Component {
    render() {

        return (
            <div className={['CookieNote', this.props.cookiesAccepted ? 'hide' : null].join(' ')}>
                <div className="text">
                    <h1>{this.props.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: this.props.text}} />
                </div>
                <div className="button">
                    <button onClick={() => this.props.acceptCookies()}>{ this.props.btnText }</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentLang: state.currentLang,
    cookiesAccepted: state.cookiesAccepted
});

const mapDispatchToProps = dispatch => ({
    acceptCookies: () => dispatch({type: 'ACCEPT_COOKIES', cookiesAccepted: true})
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieNote);
