import React from 'react';
import './AccorionItem.scss';
import {Link} from 'react-router-dom';
import Video from '../../../img/video/sand_wave_lg.mp4';

const AccordionItem = (props) => {

    let AccordionItemClasses = (typeof props.classes !== 'undefined')
        ? [...props.classes]
        : [];
    AccordionItemClasses.push('AccordionItem');

    if (props.active === true) {
        AccordionItemClasses.push('Active');
    }
    if (props.opened === true) {
        AccordionItemClasses.push('Opened');
    }

    const msZoom = (props.active && !props.pageOpen)
        ? {'zoom': 1}
        : {'zoom': 0.85};

    let linklistClasses = ['AccordionItemLinks'];

    let links = [];
    if (typeof props.links !== 'undefined') {
        if (props.links.length <= 4) {
            linklistClasses.push('buttons')
        } else {
            linklistClasses.push('list')
        }

        links = props.links.map(link => {
            return (
                <li key={link.path}>
                    <Link to={link.path}
                          className={link.classes.join(' ')}>
                        {link.text}</Link>
                </li>
            );
        });
    }

    return (
        <li className={AccordionItemClasses.join(' ')}>
            {
                AccordionItemClasses.join(' ').includes("first AccordionItem Active") &&
                (
                    <div className="bgVideo">
                        <video autoPlay muted loop playsInline>
                            <source src={Video}
                                    type='video/mp4'/>
                            <p>Sorry, your browser doesn't support embedded videos.</p>
                        </video>
                    </div>
                )
            }
            <Link className="AccordionItemLabel" to={props.path}>{props.header}</Link>
            <div className="AccordionItemBody">
                <div className="wrapper" style={msZoom}>
                    <h2 className="AccordionItemHeader">{props.header}</h2>
                    <div className="AccordionItemText" dangerouslySetInnerHTML={{__html: props.body}}/>
                    <ul className={linklistClasses.join(' ')}>
                        {links}
                    </ul>
                </div>
            </div>
        </li>
    );
};

export default AccordionItem;
