import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavigationItem.scss';

const navigationItem = ( props ) => {
  let classes = ['NavigationItem'];
  if (typeof props.className !== 'undefined') {
    classes.push(props.className)
  }
  return (
      <li className={classes.join(' ')}>
          <NavLink
              to={props.link}
              exact={props.exact}
              activeClassName="active">{props.children}</NavLink>
      </li>
  );
};

export default navigationItem;
