
const initialState = {
  apiUrl: '',
  location: '/',
  loggedIn: false,
  cookiesAccepted: false,
  homeTitle: '',
  homeContent: [],
  pageOpen: false,
  showSideDrawer: false,
  languages: [
    {
      id: 'de',
      name: 'DE',
      loginTitle: "Login/Registrierung für Ärzte",
      loginText: [
        "Ginkgo-symfona.ch ist ein Angebot der OM Pharma Schweiz, Vifor SA. Hier informieren wir Sie über kognitive Störungen, den Ginkgo biloba-Extrakt und Symfona<sup>®</sup>.",
        "Bitte beachten Sie, dass sich diese Website ausschließlich an Ärzte und qualifiziertes Fachpersonal richtet. Sie benötigen daher einen DocCheck Login um auf die Inhalte zuzugreifen."
      ],
      legalMenu: {
        classes: ['LegalMenu'],
        items: [
          {
            name: 'Impressum',
            href: 'http://www.viforpharma.ch/de/site-services/legal-notice',
            attr: {
              target: '_blank',
              hrefLang: 'de'
            }
          },
          {
            name: 'Datenschutz',
            href: 'http://www.viforpharma.ch/de/site-services/privacy-policy',
            attr: {
              target: '_blank',
              hrefLang: 'de'
            }
          }
        ]
      },
      cookieNote: {
        title: "Cookie Hinweis",
        text: '<p>Durch Akzeptieren oder durch Verwendung des DocCheck-Logins erkären Sie sich mit der Verwendung von Cookies im Sinne unserer <a href="http://www.viforpharma.ch/de/site-services/privacy-policy" hreflang="de" target="_blank">Datenschutzerklärung</a> einverstanden.</p>',
        btnText: "Akzeptieren"
      }
    },
    {
      id: 'fr',
      name: 'FR',
      loginTitle: "Enregistrement des médecins",
      loginText: [
        "Ginkgo-symfona.ch est une offre d’OM Pharma Suisse, Vifor SA. Vous y trouverez des informations sur les troubles cognitifs, l’extrait de Ginkgo biloba et Symfona<sup>®</sup>.",
        "Veuillez noter que ce site Internet s’adresse uniquement aux médecins et à un personnel qualifié. Vous aurez donc besoin d’identifiants DocCheck pour accéder aux contenus."
      ],
      legalMenu: {
        classes: ['LegalMenu'],
        items: [
          {
            name: 'Mentions obligatoires',
            href: 'http://www.viforpharma.ch/fr/site-services/legal-notice',
            attr: {
              target: '_blank',
              hreflang: 'fr'
            }
          },
          {
            name: 'Confidentialité des données',
            href: 'http://www.viforpharma.ch/fr/site-services/privacy-policy',
            attr: {
              target: '_blank',
              hreflang: 'fr'
            }
          }
        ]
      },
      cookieNote: {
        title: "Note De Cookie",
        text: '<p>En acceptant ou en utilisant le formulaire d\'enregistrement DocCheck, vous consentez à l\'utilisation de cookies conformément à notre <a href="http://www.viforpharma.ch/fr/site-services/privacy-policy" hreflang="fr" target="_blank">politique de confidentialité</a>.</p>',
        btnText: "Accepter",
      }
    },
  ],
  currentLang: '',
  accordion: {
    activeAccordionItem: 0,
    items: []
  },
  navItems: [],
  pages: {}
};

const reducer = (state = initialState, action) => {


  let interim = {...state};
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      interim.loggedIn = action.loggedIn;
      interim.cookiesAccepted = action.cookiesAccepted;
      return interim;
    case 'LOGOUT':
      interim.loggedIn = action.loggedIn;
      interim.homeTitle =  action.homeTitle;
      interim.homeContent =  action.homeTitle;
      interim.pageOpen = action.pageOpen;
      interim.showSideDrawer = action.showSideDrawer;
      interim.currentLang = action.currentLang;
      interim.accordion = action.accordion;
      interim.activeAccordionItem = action.activeAccordionItem;
      interim.navItems = action.navItems;
      interim.pages = action.pages;
      return interim;
    case 'ACCORDION_SET_ACTIVE_ITEM':
      interim.accordion.activeAccordionItem = action.id;
      return interim;
    case 'SET_PAGE_OPEN':
      interim.pageOpen = action.pageOpen;
      return interim;
    case 'ROUTE_CHANGED':
      interim.location = action.path;
      interim.pageOpen = action.pageOpen;
      return interim;
    case 'NAVIGATION_SET_ITEMS':
      interim.navItems = action.navItems;
      return interim;
    case 'ACCORDION_SET_ITEMS':
      interim.accordion.items = action.accordionItems;
      return interim;
    case 'CONTENT_SET_PAGES':
      interim.homeTitle = action.home.pageTitle;
      interim.homeContent = action.home.paragraphs;
      interim.pages = action.pages;
      return interim;
    case 'SET_LANGUAGE':
      interim.currentLang = action.currentLang;
      interim.showSideDrawer = false;
      return interim;
    case 'PAGE_UPDATE_CLOSE_DRAWER':
    case 'SIDE_DRAWER_TOGGLE':
    case 'SIDE_DRAWER_CLOSE':
      interim.showSideDrawer = action.showSideDrawer;
      return interim;
    case 'ACCEPT_COOKIES':
      interim.cookiesAccepted = action.cookiesAccepted;
      return interim;
    default:
      return state;
  }
};

export default reducer;
