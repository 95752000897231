import React, {Component} from 'react';
import 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation.min';
import './ParagraphImg.scss';

class ParagraphImg extends Component {

  render() {
    const apiDomain = process.env.REACT_APP_API_URL;
    let classes = ['Paragraph', 'Img'];
    let wrapClasses = [];
    let styles = {};

    if (
      typeof this.props.contentWidth !== 'undefined'
      && this.props.contentWidth === "1"
    ) {
      wrapClasses.push('ContentSize');
      styles = {
        'page_cont_xs': '(max-width: 560px)',
        'page_cont_s': '(min-width: 561px) and (max-width: 767px)',
        'page_cont_m': '(min-width: 768px)',
      };
    } else {
      wrapClasses.push('BreakOut');
      styles = {
        'page_break_xs': '(max-width: 560px)',
        'page_break_s': '(min-width: 561px) and (max-width: 767px)',
        'page_break_m': '(min-width: 768px) and (max-width: 991px)',
        'page_break_l': '(min-width: 992px) and (max-width: 1199px)',
        'page_break_xl': '(min-width: 1200px) and (max-width: 1499px)',
        'page_break_xxl': '(min-width: 1500px)',
      };
    }

    const path = (typeof this.props.img !== 'undefined')
      ? this.props.img.replace('public://', '')
      : '';

    let sources = [];
    for (let style in styles) {
      let formattedPath = `${apiDomain}/sites/default/files/styles/${style}/public/${path}`;
      sources.push(
        (<source key={style} media={styles[style]} srcSet={formattedPath} />)
      );

    }

    return (
      <div className={ classes.join(' ') }>
        <div className={ wrapClasses.join(' ') }>
          <picture>
            {sources}
            <img srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                 alt={this.props.alt} />
          </picture>
        </div>
      </div>
    );
  };
}

export default ParagraphImg;
