import React, {Component} from 'react';
import './Menu.scss';

class Menu extends Component {



  render = () => {


    /**
     * {
     *   name: string,
     *   href: string
     *   attr: object
     * }
     */
    let items = this.props.items.map((item, i) => {
      const allowed = ['download','media','ping','rel','target','charset',
        'coords','hrefLang','methods','name','rev','type','urn'];
      let attributes = {};
      for (var key in item.attr) {
        if (item.attr.hasOwnProperty(key) && allowed.indexOf(key) >= 0) {
          attributes[key] = item.attr[key];
        }
      }
      return <li key={i}>
        <a href={item.href} {...attributes}><span>{item.name}</span></a>
      </li>;
    });


    return <div className={['MenuWrap', [...this.props.classes]].join(' ')}>
      <ul className={['Menu', [...this.props.classes]].join(' ')}>
        {items}
      </ul>
    </div>;
  }
}

export default Menu;
