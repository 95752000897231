import React from 'react'
import './JumpNav.scss';
import Scrollspy from 'react-scrollspy';
import {configureAnchors} from 'react-scrollable-anchor';

configureAnchors({offset: 0, scrollDuration: 400, keepLastAnchorHash: false});


function JumpNav({navItems}) {
  const navHashes = [];
  const jumpNavItems = navItems.map(navItem => {
    navHashes.push(navItem.hash + "-spy");
    return (
      <li key={navItem.title}>
        <a href={"#" + navItem.hash}><span>{navItem.title}</span></a>
      </li>
    )
  });

  return (
    <Scrollspy items={navHashes} currentClassName="is-current" className="JumpNav">
      {jumpNavItems}
    </Scrollspy>
  )
}

export default JumpNav;



