import React, {Component} from 'react';
import {connect} from 'react-redux';

import './Accordion.scss';
import AccordionItem from "./AccordionItem/AccordionItem";

class Accordion extends Component {

    render() {
        let items = this.props.items.map(item => {
            let isActive = this.props.activeAccordionItem === item.id;

            return (
                <AccordionItem
                    key={item.id}
                    id={item.id}
                    path={item.path}
                    header={item.header}
                    classes={item.classes}
                    active={isActive}
                    pageOpen={this.props.pageOpen}
                    links={item.links}
                    body={item.body}
                />
            )
        });

        let classes = ['Accordion'];

        return (
            <ul className={classes.join(' ')}>
                {items}
            </ul>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeAccordionItem: state.accordion.activeAccordionItem,
        items: state.accordion.items,
        pageOpen: state.pageOpen
    }
};

export default connect(mapStateToProps)(Accordion);
