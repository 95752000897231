import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Paragraphs from '../../components/Paragraphs/Paragraphs';
import './Page.scss';


class Page extends Component {

  render() {
    let title;
    let paragraphs = [];
    let pageClasses = ['Page'];
    const pathname = this.props.location.pathname;

    if (typeof this.props.pages[pathname] !== 'undefined') {

      const page = this.props.pages[pathname];

      if (typeof page.pageTitle !== 'undefined') {
        title = (<h1>{page.pageTitle}</h1>);
      }
      if (typeof page.paragraphs !== 'undefined') {
        paragraphs = (<Paragraphs paragraphs={page.paragraphs} jumpnav="1"/>)
      }
      if (typeof page.pageClasses !== 'undefined') {
        pageClasses.push(page.pageClasses);
      }
    }

    return (
      <article className={pageClasses.join(' ')}>
        {title}
        {paragraphs}
      </article>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pages: state.pages
  }
};

export default withRouter(connect(mapStateToProps)(Page));
